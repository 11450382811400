<template>
    <div class="d-flex flex-column fill-height">
        <v-col class="pa-0 ma-0 mb-auto">
            <v-row no-gutters>
                <v-col class="col-9-5 d-flex flex-column pt-3">
                    <div>
                        <span class="header-title">{{ Office__title  }}</span>
                        <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != null && Office__companyno != ''">{{ '(' + Office__companyno + ')' }}</span>
                    </div>
                    <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                    <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].filter(Boolean).join(', ')  }}</span>
                    <div class="d-flex flex-row">
                        <span class="header-subtitle pt-1 pb-0" v-if="Office__contracttel != null  && Office__contracttel != ''">{{ $t('message.tel') + ': ' + Office__contracttel  }}</span>
                        <span :class="'header-subtitle pt-1 pb-0' + (Office__contracttel != null && Office__contracttel != '' ? ' ml-10' : '')" v-if="Contract__office_id == 20">{{ getOfficeTaxId() + ' (Head Office)'}}</span>
                    </div>
                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__contractemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                </v-col>
                <v-col class="col-2-5 text-right">
                    <img :src="appLogo" class="mb-3 mr-3" width="130px"/>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ documentTitle }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4">
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.buyer') }}:</v-col>
                        <v-col cols="8" class="d-flex flex-column">
                            <span>{{ Customer__title }}</span>
                            <span>{{ Customer__address1 }}</span>
                            <span>{{ Customer__address2 }}</span>
                            <span>{{ Customer__address3 }}</span>
                            <span>{{ Customer__city + ' ' + (Customer__postcode != null ? Customer__postcode : '') }}</span>
                            <span>{{ Customer__state }}</span>
                            <span>{{ Customer__country }}</span>
                            <span>{{ getTaxId() }}</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.attn') }}:</v-col>
                        <v-col cols="8">{{ Contact__prefix ? Contact__prefix + ' ' : '' }}{{ Contact__name ? Contact__name : $t('message.na').toUpperCase() }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.tel') }}:</v-col>
                        <v-col cols="8">{{ Contact__tel ? Contact__tel : $t('message.na').toUpperCase() }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.hp') }}:</v-col>
                        <v-col cols="8">{{ Contact__hp ? Contact__hp : $t('message.na').toUpperCase() }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.email') }}:</v-col>
                        <v-col cols="8">{{ Contact__email ? Contact__email : $t('message.na').toUpperCase() }}</v-col>
                    </v-row>
                </v-col>
                <v-col class="col-4-5">
                    <v-row no-gutters>
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.paymentTerms') }}:</v-col>
                        <v-col cols="7">{{ Paymentterm__title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="5" class="font-weight-bold">{{ $t('message.deliveryTerms') }}:</v-col>
                        <v-col cols="7">{{ contractIncoterm }}</v-col>
                    </v-row>
                </v-col>
                <v-col class="col-3-5">
                    <v-row no-gutters>
                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.date') }}:</v-col>
                        <v-col cols="6">{{ Contract__contractdate ? formatDate(Contract__contractdate) : '' }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.invoiceNo') }}:</v-col>
                        <v-col cols="6">{{ Contract__pi_no }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.contractNo') }}:</v-col>
                        <v-col cols="6">{{ Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.marketing') }}:</v-col>
                        <v-col cols="6">{{ Salesperson__name }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-simple-table
                class="specification-table mt-4"
                dense
            >
                <template v-slot:default>
                    <tr class="table-header">
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-10-pct">{{ $t('message.species') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-18-pct">{{ $t('message.description') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-10-pct">{{ $t('message.grade') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-12-pct">{{ $t('message.size') }}</td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1">{{ $t('message.quantity') }}</td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1">
                            <div class="d-flex flex-column">
                                <span>{{ $t('message.unitPrice') }}</span>
                                <span>{{ getCurrencyCode(Contract__currency_id) }}</span>
                            </div>
                        </td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1">
                            <div class="d-flex flex-column">
                                <span>{{ $t('message.total') }}</span>
                                <span>{{ getCurrencyCode(Contract__currency_id) }}</span>
                            </div>
                        </td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1" v-if="[5].includes(Contract__salestype_id)">
                            <div class="d-flex flex-column">
                                <span>{{ $t('message.unitPrice') }}</span>
                                <span>{{ getCurrencyCode(Office__currency_id) }}</span>
                            </div>
                        </td>
                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1" v-if="[5].includes(Contract__salestype_id)">
                            <div class="d-flex flex-column">
                                <span>{{ $t('message.total') }}</span>
                                <span>{{ getCurrencyCode(Office__currency_id) }}</span>
                            </div>
                        </td>
                    </tr>
                    <template v-for="(item,index) in Salesitems" v-if="item.type == 'default'">
                        <tr class="table-row-main" v-if="index == 0 ">
                            <td colspan="9" v-if="useLcDescriptions">{{ item.Salesdescription.lcdescription }}</td>
                            <td colspan="9" v-else>{{ item.Species.title }}</td>
                        </tr>
                        <tr class="table-row" v-else-if="index > 0 && Salesitems[index - 1].Salesdescription.title != item.Salesdescription.title">
                            <td colspan="9" v-if="useLcDescriptions">{{ item.Salesdescription.lcdescription }}</td>
                            <td colspan="9" v-else>{{ item.Species.title }}</td>
                        </tr>
                        <tr class="table-row-sub">
                            <td></td>
                            <td class="px-0">{{ (useLcDescriptions == false ? getProduct(item.Productgroup.title, item.Salesitem.description, item.Spec.title) + (item.Salesitem.description ? item.Salesitem.description : item.Spec.title) : '') + getCertificationLabel(item.Salesdescription.title,item.Salesitem.certification_id) }}</td>
                            <td class="px-0">{{ item.Grade.title }}</td>
                            <td class="px-0">{{ item.Size.title }}</td>
                            <td class="px-0 text-right text-no-wrap">
                                <template v-if="Contract__salestype_id == 5 && Contract__office_id == 20">
                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.itemQtyInCbf, '0,0.000') + ' CBF' : '' }}
                                </template>
                                <template v-else>
                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.itemqty,uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.title : '' }}
                                </template>
                            </td>
                            <td class="px-0 text-right">
                                <template v-if="Contract__salestype_id == 5 && Contract__office_id == 20">
                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.unitPriceInCbf,'0,0.0000') : '' }}
                                </template>
                                <template v-else>
                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.unitprice,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : contractCurrencyFormatPrecision) : '' }}
                                </template>
                            </td>
                            <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.amount,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : contractCurrencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) + ')' }}</td>
                            <td class="px-0 text-right" v-if="[5].includes(Contract__salestype_id)">
                                <template v-if="Contract__office_id == 20">
                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.unitPriceConvertedInCbf, '0,0.0000') : '' }}
                                </template>
                                <template v-else>
                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.unitprice_converted,contractCurrencyFormatPrecision) : '' }}
                                </template>
                            </td>
                            <td class="px-0 text-right" style="padding-left: 10px !important;" v-if="[5].includes(Contract__salestype_id)">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.amount_converted,contractCurrencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.amount_converted,currencyFormatPrecision) + ')' }}</td>
                        </tr>
                    </template>
                    <template v-else-if="item.type == 'othercharge' && item.Salesitem.chargeto == 0">
                        <tr>
                            <td></td>
                            <td class="px-0">{{ item.Salesitem.size }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="px-0 text-right">{{ formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) }}</td>
                            <td class="px-0 text-right" v-if="[5].includes(Contract__salestype_id)">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.unitprice_converted,currencyFormatPrecision) : '' }}</td>
                            <td class="px-0 text-right" style="padding-left: 10px !important;" v-if="[5].includes(Contract__salestype_id)">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.amount_converted,currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.amount_converted,currencyFormatPrecision) + ')' }}</td>
                        </tr>
                    </template>
                </template>
            </v-simple-table>
        </v-col>
        <div class="px-0 pb-0 pt-3 ma-0 mt-auto">
            <v-simple-table
                class="specification-table"
                dense
            >
                <template v-slot:default>
                    <tr>
                        <td :colspan="([5].includes(Contract__salestype_id)) || (taxInvoice == true) ? 9 : 6" class="px-0">
                            <div style="white-space: pre-line" v-if="Contract__note != null">
                                <div v-if="Contract__note.indexOf('Remark') == -1">{{ $t('message.remarks') }}</div>
                                <div>{{ Contract__note.trim() }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="px-0 font-weight-bold" colspan="3">
                            <div class="d-flex flex-column" v-if="[7,8].includes(Contract__fsc) == false && Contract__fsc != null">
                                <span>{{ $t('message.certCode') + ' : ' + getCertification(Contract__fsc,'cert_no') }}</span>
                                <span>{{ $t('message.expiryDate') + ' : ' + getCertification(Contract__fsc,'cert_expiry_date') }}</span>
                            </div>
                        </td>
                        <td :colspan="([5].includes(Contract__salestype_id)) || (taxInvoice == true) ? 6 : 3">
                            <v-row no-gutters>
                                <v-col class="d-flex flex-row col-12" v-if="[5].includes(Contract__salestype_id)">
                                    <span>{{ $t('message.exchangeRate') + ' ' + servicedSalesExchangeRateLabel + ' = ' +  formatThisNumber(Contract__pi_exrate,'0.0000') + ' ' + $t('message.asPer') + ' ' + formatDate(Contract__pi_exratedate) }}</span>
                                </v-col>
                                <v-col class="d-flex flex-row col-12" v-if="taxInvoice">
                                    <span>{{ $t('message.exchangeRate') +' '+ formatThisNumber(Invoice__gstrmrate,'0.0000') + ' ' + $t('message.date') + ': ' + formatDate(Contract__pi_exratedate) }}</span>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                    <template v-if="[1].includes(Contract__salestype_id)">
                        <tr>
                            <td class="table-total pa-0 border-top-1 width-10-pct"></td>
                            <td class="table-total pa-0 border-top-1 width-20-pct"></td>
                            <td class="table-total pa-0 border-top-1 width-10-pct"></td>
                            <td class="table-total pa-0 border-top-1 width-10-pct"></td>
                            <td colspan="2" class="table-total pa-0 border-top-1 text-right">{{ $t('message.deposit') + ' ' + Payment__amountinpercent + '%' }}</td>
                            <td class="table-total pa-0 border-top-1 width-10-pct text-right">{{ formatThisNumber(Salesitems__itemsTotalAmount, contractCurrencyFormatPrecision) }}</td>
                        </tr>
                        <tr>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1 text-right">{{ $t('message.totalDue') }}</td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1 text-right">{{ formatThisNumber(Salesitems__itemsTotalAmountWithTax, contractCurrencyFormatPrecision) }}</td>
                        </tr>
                    </template>
                    <template v-if="[5].includes(Contract__salestype_id)">
                        <tr>
                            <td class="table-total pa-0 border-top-1 width-10-pct"></td>
                            <td class="table-total pa-0 border-top-1 width-20-pct"></td>
                            <td class="table-total pa-0 border-top-1 width-10-pct"></td>
                            <td class="table-total pa-0 border-top-1 width-10-pct"></td>
                            <td colspan="2" class="table-total pa-0 border-top-1 text-right">{{ $t('message.deposit') + ' ' + Payment__amountinpercent + '%' }}</td>
                            <td class="table-total pa-0 border-top-1 width-10-pct text-right">{{ formatThisNumber(Salesitems__itemsTotalAmount, contractCurrencyFormatPrecision) }}</td>
                            <td class="table-total pa-0 border-top-1 width-10-pct"></td>
                            <td class="table-total pa-0 border-top-1 width-10-pct text-right" style="padding-left: 10px !important;">{{ formatThisNumber(Salesitems__itemsTotalAmountConverted, contractCurrencyFormatPrecision) }}</td>
                        </tr>
                        <template v-if="office.Office.tax == true">
                            <tr>
                                <td class="table-total pa-0"></td>
                                <td class="table-total pa-0"></td>
                                <td class="table-total pa-0"></td>
                                <td class="table-total pa-0"></td>
                                <td class="table-total pa-0"></td>
                                <td class="table-total pa-0 text-right">{{ office.Office.taxterm + ' ' + (Contract__gstpercent != null ? Contract__gstpercent : office.Office.taxrate) + '%' }}</td>
                                <td class="table-total pa-0 text-right">{{ formatThisNumber(Salesitems__itemsTotalTax, contractCurrencyFormatPrecision) }}</td>
                                <td class="table-total pa-0"></td>
                                <td class="table-total pa-0 text-right" style="padding-left: 10px !important;">{{ formatThisNumber(Salesitems__itemsTotalTaxConverted, contractCurrencyFormatPrecision) }}</td>
                            </tr>
                        </template>
                        <tr>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1 text-right">{{ $t('message.totalDue') }}</td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1 text-right">{{ formatThisNumber(Salesitems__itemsTotalAmountWithTax, contractCurrencyFormatPrecision) }}</td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                            <td class="table-total pa-0 font-weight-bold border-bottom-1 text-right" style="padding-left: 10px !important;">{{ formatThisNumber(Salesitems__itemsTotalAmountWithTaxConverted, contractCurrencyFormatPrecision) }}</td>
                        </tr>
                    </template>
                </template>
            </v-simple-table>
            <div id="page-break" style="page-break-after:always" v-if="Contract__longform == 1"></div>
            <v-row no-gutters class="pt-2" v-if="Contract__showbank">
                <v-col cols="2" class="font-weight-bold">{{ $t('message.pleaseRemitTo') }}:</v-col>
                <v-col cols="10">
                    <v-row no-gutters v-if="Bank__beneficiary != null && Bank__beneficiary != ''">
                        <v-col class="col-2-5">{{ $t('message.beneficiaryName') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__beneficiary }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__account != null && Bank__account != ''">
                        <v-col class="col-2-5">{{ $t('message.account') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__account }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__title != null && Bank__title != ''">
                        <v-col class="col-2-5">{{ $t('message.bank') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__title }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__swiftcode != null && Bank__swiftcode != ''">
                        <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__swiftcode }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__address != null && Bank__address != ''">
                        <v-col class="col-2-5">{{ $t('message.bankAddress') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__address + (Bank__country != null ? ', ' + Bank__country : '') }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__agent !== null && Bank__agent != ''">
                        <v-col class="col-2-5">{{ $t('message.agentBank') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__agent }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__agentswiftcode != null && Bank__agentswiftcode != ''">
                        <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__agentswiftcode }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.importantNotes') + ':' }}</v-col>
                <v-col cols="12" v-for="footerNote in invoiceFooterNotes" v-if="Invoice__footer_notes.includes(footerNote.id)" :key="footerNote.id">
                    <div class="d-flex flex-row align-stretch">
                        <span class="px-1">{{ footerNote.id + '.' }}</span>
                        <span class="footer-note text-wrap">{{ parseFooterNote(footerNote) }}</span>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="text-center font-weight-bold">{{ $t('message.documentElectronicallyGenerated') }}</v-col>
            </v-row>
        </div>
        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
    </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";
// import {mapFields, mapMultiRowFields} from "vuex-map-fields";
// import {mapActions, mapGetters} from "vuex";
import {formatDate, isNumeric, numberFormat, nl2br, log} from "../../../helpers/helpers";
import {api} from "../../../api";
import {
    buyerCaptionTypes,
    containerSizes,
    // currencies,
    deliveryAddressTypes,
    incoterms,
    invoiceFooterNotes,
    offices,
    uofm
} from "../../../store/modules/appic/constants"

export default {
    name: "PrintContractProformaInvoiceV2",
    props: ['contractId'],
    data() {
        return {
            appLogo: AppConfig.appSessionLogo,
            deliveryAddresses: [],
            loaded: false,
            buyerCaptionTypes: buyerCaptionTypes,
            certificationsNew: [],
            containerSizes: containerSizes,
            currencies: [],
            deliveryAddressTypes: deliveryAddressTypes,
            incoterms: incoterms,
            invoiceFooterNotes: invoiceFooterNotes,
            lcTerms: [],
            // lcTerms: [4029,4030,4096,4219,4276,4302,4317,4318,4347,4378,4431,4212,4335],
            taxInvoice: false,
            offices: offices,
            uofmQty: uofm.qty,
            useLcDescriptions: false,
            Bank__address: null,
            Bank__agent: null,
            Bank__agentswiftcode: null,
            Bank__country: null,
            Bank__id: null,
            Bank__title: null,
            Bank__account: null,
            Bank__swiftcode: null,
            Bank__beneficiary: null,
            Contact__email: null,
            Contact__hp: null,
            Contact__name: null,
            Contact__prefix: null,
            Contact__tel: null,
            Contract__containercount: null,
            Contract__containersize_id: 1,
            Contract__contractdate: null,
            Contract__currency_id: 2,
            Contract__fsc: 0,
            Contract__gstpercent: 0,
            Contract__id: null,
            Contract__incoterm_id: null,
            Contract__incotermport: null,
            Contract__is_export: 0,
            Contract__loadingport_id: null,
            Contract__longform: 0,
            Contract__office_id: 1,
            Contract__partial_no: null,
            Contract__paymentstatement_id: null,
            Contract__pi_exrate: null,
            Contract__pi_exratedate: null,
            Contract__pi_no: null,
            Contract__note: null,
            Contract__revision_no: null,
            Contract__salestype_id: 1,
            Contract__shippingport_id: null,
            Contract__showbank: null,
            Contract__title: null,
            Contract__version: null,
            Customer__title: null,
            Customer__address1: null,
            Customer__address2: null,
            Customer__address3: null,
            Customer__city: null,
            Customer__company_id: null,
            Customer__country: null,
            Customer__country_id: null,
            Customer__gstno: null,
            Customer__postcode: null,
            Customer__state: null,
            Customer__taxno_type: null,
            Customer__uscc: null,
            Deposit__amount: 0,
            Deposit__amount_converted: 0,
            Deposit__exchange_rate: 1,
            Invoice__footer_notes: [1,2,3,4],
            Loadingport__title: null,
            Office__title: null,
            Office__address1: null,
            Office__address2: null,
            Office__city: null,
            Office__contracttel: null,
            Office__companyno: null,
            Office__country: null,
            Office__currency_id: 1,
            Office__contractemail: null,
            // Office__contracttel: null,
            Office__gstno: null,
            Office__postcode: null,
            Office__state: null,
            Office__stamp: null,
            Office__stamp_ws: null,
            Office__taxno_type: null,
            Office__website: null,
            Origin__name: null,
            Payment__amountinpercent: 0,
            Payment__dprequired: 0,
            Paymentterm__title: null,
            Shippingport__title: null,
            Salesperson__name: null,
            Salesitems: [],
            Salesitems__itemsTotalAmount: 0,
            Salesitems__itemsTotalAmountConverted: 0,
            Salesitems__itemsTotalQty: [],
            Salesitems__itemsTotalTax: 0,
            Salesitems__itemsTotalTaxConverted: 0,
            Salesitems__itemsTotalAmountWithTax: 0,
            Salesitems__itemsTotalAmountWithTaxConverted: 0,
        };
    },
    computed: {
        buyerCaption() {
            return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype)?.title_first
        },
        contractIncoterm() {
            let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Contract__incoterm_id)?.Incoterm?.title;
            let incotermport = null;
            switch(this.Contract__incotermport){
                case 0: //POD
                    if(this.Contract__shippingport_id != null) {
                        incotermport = ' ' + this.Shippingport__title
                    }
                    break;
                case 1: //POL
                    if(this.Contract__loadingport_id != null) {
                        incotermport = ' ' + this.Loadingport__title
                    }
                    break;
                case 2:
                    incotermport = ''
                    break
            }
            return incoterm + (incotermport ? incotermport : '');
        },
        contractCurrencyFormatPrecision () {
            return this.currencies.find((currency) => currency?.Currency?.id === this.Contract__currency_id)?.Currency?.formatPrecision
        },
        currencyFormatPrecision () {
            return this.currencies.find((currency) => currency?.Currency?.id === this.Contract__currency_id)?.Currency?.formatPrecision
        },
        deliveryCaption() {
            return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype)?.title_last
        },
        documentTitle() {
            let title = this.$t('message.proformaInvoice')
            if(this.Contract__office_id == 20) title = this.$t('message.depositTaxInvoice')
            return title
        },
        footerNoteReplacements (){
            let obj = {}
            if([1,3].includes(this.Contract__office_id)){
                obj.interest_charge_pct = '1.5%'
            } else {
                obj.interest_charge_pct = '2%'
            }
            return obj
        },
        office (){
            let office = this.offices.find(o => o.Office.id == this.Contract__office_id)
            if(office == null){
                office = this.offices.find(o => o.Office.id == 3)
            }
            return office
        },
        servicedSalesExchangeRateLabel() {
            let label = []
            let contractCurrency = this.currencies.find(c => c.Currency.id == this.Contract__currency_id)
            // let office = this.offices.find(o => o.Office.id == this.Contract__office_id)
            let officeCurrency = this.currencies.find(c => c.Currency.id == this.office.Office.currency_id)
            if(contractCurrency) label.push(contractCurrency.Currency.code)
            if(officeCurrency) label.push(officeCurrency.Currency.code)
            if(label.length > 0){
                return label.filter(Boolean).join('-')
            }
            return null
        },
    },
    methods: {
        calculateTaxOnItems () {
            return new Promise((resolve) => {
                this.Salesitems.forEach((item, index) => {
                    this.Salesitems[index]['Salesitem']['unitprice_converted'] = parseFloat(this.Salesitems[index]['Salesitem']['unitprice']) * this.Contract__pi_exrate

                    const decimals = this.getTaxCurrencyDecimals(this.Office__currency_id)
                    const priceConverted = this.Salesitems[index]['Salesitem']['unitprice_converted'].toFixed(decimals);

                    this.Salesitems[index]['Salesitem']['amount_converted'] = parseFloat(this.Salesitems[index]['Salesitem']['itemqty']) * parseFloat(priceConverted)
                    if(this.Contract__salestype_id == 5 && this.Contract__office_id == 20){
                        this.Salesitems[index]['Salesitem']['unitPriceConvertedInCbf'] = this.Salesitems[index]['Salesitem']['amount_converted'].toFixed(2) / this.Salesitems[index]['Salesitem']['itemQtyInCbf'].toFixed(3)
                    }
                })
                resolve('done')
            })
        },
        formatDate,
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        getBuyerContact() {
            if(this.Customer__company_id != null) {
                api
                    .get('/print/companies/' + this.Customer__company_id + '/contacts')
                    .then((response) => {
                        let contacts = response.data.data
                        let contact = contacts[0]
                        this.Contact__email = contact.Contact.email
                        this.Contact__hp = contact.Contact.hp
                        this.Contact__name = contact.Contact.name
                        this.Contact__prefix = contact.Contact.prefix
                        this.Contact__tel = contact.Contact.tel
                    })
            }
        },
        getCertifications() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/certifications")
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getCertification(certificationId, certificationInfo) {
            let certificationObj = this.certificationsNew.find(c => c.Certification.id == certificationId)
            if(certificationObj){
                return certificationObj?.Certification[certificationInfo]
            }
            return ''
        },
        getCertificationLabel(salesDescription, certificationId){
            if(certificationId != null && certificationId != 0){
                let certificationText = this.certificationsNew.find(c => c.Certification.id == certificationId)?.Certification?.title

                let searchStrings =  ['FSC','PEFC','SVLK',certificationText];
                let stringFound = false

                searchStrings.forEach(string => {
                    if(salesDescription.indexOf(string) > 0) stringFound = true
                })

                if(stringFound){
                    return ''
                } else {
                    return ', ' + certificationText
                }
            } else {
                return ''
            }
        },
        getCurrencyCode(currencyId) {
            let code = 'USD'
            const currency = this.currencies.find((currency) => currency.Currency.id == currencyId)
            if(currency) code = currency.Currency.code
            return code
        },
        getCurrencies() {
            return new Promise((resolve, reject) => {
                // log('get currencies')
                api
                    .get("/print/currencies",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getLcBasedPaymentTerms() {
            return new Promise((resolve, reject) => {
                log('get lc based payment terms')
                api
                    .get("/print/paymentterms/lc/ids",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getOfficeTaxId () {
            let taxId = ''
            if(this.Office__gstno != null && this.Office__taxno_type != null) taxId = this.Office__taxno_type + ': ' + this.Office__gstno
            if(this.Office__gstno != null && this.Office__taxno_type == null) taxId = this.Office__gstno
            return taxId
        },
        getProduct(itemProduct, itemDescription, itemSpec){
            if(itemDescription != null && itemDescription != ''){
                if(itemDescription.includes(itemProduct) == false){
                    return (itemProduct != null ? itemProduct + ', ' : '')
                } else {
                    return ''
                }
            } else {
                if(itemSpec != null && itemSpec != '') {
                    if (itemSpec.includes(itemProduct) == false) {
                        return (itemProduct != null ? itemProduct + ', ' : '')
                    } else {
                        return ''
                    }
                }
                return itemProduct != null ? itemProduct : ''
            }
        },
        getTaxCurrency (val) {
            let currency = this.currencies.find((currency) => currency.Currency.id == val)
            if(currency.Currency.code == 'MYR'){
                return 'RM'
            } else {
                return currency.Currency.code
            }
        },
        getTaxCurrencyPrecision (val) {
            let currency = this.currencies.find((currency) => currency.Currency.id == val)
            return currency.Currency.formatPrecision
        },
        getTaxCurrencyDecimals (val) {
            let currency = this.currencies.find((currency) => currency.Currency.id == val)
            return currency.Currency.decimals
        },
        getTaxId () {
            let taxId = ''
            if(this.Customer__gstno != null && this.Customer__taxno_type != null) taxId = this.Customer__taxno_type + ': ' + this.Customer__gstno
            if(this.Customer__gstno != null && this.Customer__taxno_type == null) taxId = this.Customer__gstno
            return taxId
        },
        loadContractById (contract_id) {
            let self = this
            return new Promise((resolve, reject) => {
                api
                    .get("/print/contracts/" + contract_id,{
                    })
                    .then(response => {
                        for(let key in response.data.data[0]){
                            self[key] = response.data.data[0][key]
                        }
                        resolve(contract_id)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        loadSalesItemsByContract (contract_id) {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/contracts/" + contract_id + '/salesitems',{
                    })
                    .then(response => {
                        let salesItems = response.data.data
                        salesItems.forEach((item, index) => {
                            salesItems[index]['Salesitem']['unitprice_converted'] = 0
                            salesItems[index]['Salesitem']['amount_converted'] = 0
                            salesItems[index]['Salesitem']['itemQtyInCbf'] = 0
                            salesItems[index]['Salesitem']['unitPriceInCbf'] = 0
                            salesItems[index]['Salesitem']['unitPriceConvertedInCbf'] = 0
                            //add conditions for STH contracts
                            if(this.Contract__salestype_id == 5 && this.Contract__office_id == 20){
                                //convert quantities and unit prices to CBF
                                let conversionFactor = 1
                                switch(salesItems[index]['Salesitem']['measurement_id']){
                                    case 1: //M3
                                        conversionFactor = 35.31467
                                        break;
                                    case 2://MBF
                                        conversionFactor = 83.33333333
                                        break;
                                }
                                salesItems[index]['Salesitem']['itemQtyInCbf'] = salesItems[index]['Salesitem']['itemqty'] * conversionFactor
                                salesItems[index]['Salesitem']['unitPriceInCbf'] = salesItems[index]['Salesitem']['amount'].toFixed(2) / salesItems[index]['Salesitem']['itemQtyInCbf'].toFixed(3)
                            }
                        })
                        resolve(salesItems)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        nl2br,
        parseFooterNote (footerNote) {
            if(footerNote.replace){
                if(this.footerNoteReplacements[footerNote.replace] != null){
                    return footerNote.text.replace('<<' + footerNote.replace + '>>',this.footerNoteReplacements[footerNote.replace])
                }
            } else {
                return footerNote.text
            }
        },
        async updateTotals () {
            let amount = 0, amount_converted = 0, quantities = []
            await this.Salesitems.forEach((item) => {
                if(item.type == 'credit') {
                    amount -= isNumeric(item.Salesitem.amount) ? parseFloat(item.Salesitem.amount) : 0;
                    amount_converted -= isNumeric(item.Salesitem.amount_converted) ? parseFloat(item.Salesitem.amount_converted) : 0;
                } else {
                    amount += isNumeric(item.Salesitem.amount) ? parseFloat(item.Salesitem.amount) : 0;
                    amount_converted += isNumeric(item.Salesitem.amount_converted) ? parseFloat(item.Salesitem.amount_converted) : 0;
                }

                let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
                if(idx !== -1){
                    quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
                } else {
                    if(item.type == 'default') {
                        let obj = {'Total': {}}
                        obj['Total']['uofm'] = item.Measurement.title;
                        obj['Total']['qty'] = isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
                        quantities.push(obj)
                    }
                }
            })
            let decimals = this.getTaxCurrencyDecimals(this.Office__currency_id)
            let dpRate = 1
            if(this.Payment__dprequired == 1 && this.Payment__amountinpercent > 0){
                dpRate = this.Payment__amountinpercent / 100
            }
            this.Salesitems__itemsTotalAmount = Math.round(((amount * dpRate) + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)

            this.Salesitems__itemsTotalAmountConverted = Math.round(((amount_converted * dpRate) + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)
            this.Salesitems__itemsTotalQty = quantities

            if(this.office.Office.tax == true){
                let taxRate = this.office.Office.taxrate / 100
                this.Contract__gstpercent = this.office.Office.taxrate

                if(this.Contract__salestype_id == 5 && this.Contract__office_id == 20){
                    //special case for SS TH
                    this.Salesitems__itemsTotalTax = this.Salesitems__itemsTotalAmount * taxRate
                    this.Salesitems__itemsTotalTaxConverted = this.Salesitems__itemsTotalAmountConverted * taxRate
                    this.Salesitems__itemsTotalAmountWithTax = (this.Salesitems__itemsTotalAmount) + this.Salesitems__itemsTotalTax
                    this.Salesitems__itemsTotalAmountWithTaxConverted = this.Salesitems__itemsTotalAmountConverted + this.Salesitems__itemsTotalTaxConverted
                } else {
                    this.Salesitems__itemsTotalTax = amount * taxRate
                    this.Salesitems__itemsTotalTaxConverted = amount * taxRate * this.Contract__pi_exrate
                    this.Salesitems__itemsTotalAmountWithTax = this.Salesitems__itemsTotalAmount + this.Salesitems__itemsTotalTax
                    this.Salesitems__itemsTotalAmountWithTaxConverted = this.Salesitems__itemsTotalAmountConverted + this.Salesitems__itemsTotalTaxConverted
                }
            } else {
                this.Salesitems__itemsTotalTax = 0
                this.Salesitems__itemsTotalTaxConverted = 0
                this.Salesitems__itemsTotalAmountWithTax = this.Salesitems__itemsTotalAmount + this.Salesitems__itemsTotalTax
                this.Salesitems__itemsTotalAmountWithTaxConverted = this.Salesitems__itemsTotalAmountConverted + this.Salesitems__itemsTotalTaxConverted
            }
        },
        uofmPrecision (uofm) {
            //Add bypass for Export Sales where M3 has 4 decimal digits
            if(this.Contract__is_export == 1){
                if(uofm.trim() == 'M3'){
                    return '0,0.000'
                } else {
                    return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                }
            } else {
                return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
            }
        },
    },
    created() {
        this.getLcBasedPaymentTerms()
            .then((lcPaymentTerms) => {
                this.lcTerms = lcPaymentTerms
            })
            .catch()
        this.getCurrencies()
            .then((currencies) => {
                this.currencies = currencies
            })
            .catch()
        this.getCertifications()
            .then((certifications) => {
                this.certificationsNew = certifications
            })
            .catch()
        this.loadContractById(this.contractId)
            .then(()=>{
                //determine Office__currency_id
                if(this.Contract__salestype_id == 5){
                    let office = this.offices.find(o => o.Office.id == this.Contract__office_id)
                    if(office){
                        this.Office__currency_id = office.Office.currency_id
                    }
                }
                //get buyer contacts
                this.getBuyerContact()

                if(this.lcTerms.includes(this.Contract__paymentstatement_id)){
                    this.useLcDescriptions = true
                }

                this.loadSalesItemsByContract(this.Contract__id)
                    .then((salesItems)=>{
                        this.Salesitems = salesItems
                        if(this.Contract__salestype_id == 5){
                            this.calculateTaxOnItems()
                                .then(()=> {
                                    this.updateTotals()
                                        .then(() => {
                                            this.loaded = true
                                        })
                                })
                        } else {
                            this.updateTotals()
                                .then(() => {
                                    this.loaded = true
                                })
                        }
                    })
            })
    }
}
</script>

<style>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}
.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.col-4-5 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}
.document-caption {
    font-size: 15px !important;
    line-height: 17px;
}
.footer-note {
    font-size: 8pt !important;
}
.header-title {
    font-size: 19pt !important;
    line-height: 24px;
    font-weight: bold !important;
}
.header-subtitle {
    font-size: 10pt !important;
}
.specification-table table tr td {
    font-family: 'AppicArial' !important;
    line-height:14px !important;
    font-size:9pt !important;
    padding:2px !important;
}
.specification-table table tr.table-header td {
    font-family: 'AppicArial' !important;
    line-height:14px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 15px !important;
    vertical-align: top !important;
}
.specification-table table tr.table-row-main td {
    font-family: 'AppicArial' !important;
    line-height:17px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 17px !important;
}
.specification-table table tr.table-row-sub td {
    font-family: 'AppicArial' !important;
    line-height:13px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 13px !important;
}
.specification-table table tr td.table-total {
    height: 8px !important;
}
.stamp-area {
    height: 100px;
}
.v-application {
    font-family: 'AppicArial' !important;
    line-height:14px !important;
    font-size:9pt !important;
    padding:2px !important;
}
.width-18-pct {
    width: 18% !important;
}
.width-15-pct {
    width: 15% !important;
}
.width-20-pct {
    width: 20% !important;
}
.width-10-pct {
    width: 10% !important;
}
.width-12-pct {
    width: 12% !important;
}
.width-14-pct {
    width: 14% !important;
}
body {
    width: 18.5cm !important;
    margin: 0 !important;
}
</style>